<template>
  <div class="corporateInformation-view">
    <div class="title_wrap">功能权限：</div>
    <div class="con_wrap">
      <div class="system_wrap">
        <el-checkbox v-model="isHome">首页资讯</el-checkbox>
      </div>
      <div class="system_wrap">
        <el-checkbox v-model="isUse">是否使用原系统官网</el-checkbox>
      </div>
      <div class="system_wrap">
        <el-checkbox v-model="isZT">专题区</el-checkbox>
        <div class="choose_title_y">
          <div class="title1" v-if="ruleForm.template == 1">已选择模板一</div>
          <div class="title1" v-if="ruleForm.template == 2">已选择模板二</div>
          <div class="title1" v-if="ruleForm.template == 3">已选择模板三</div>
          <div class="title1" v-if="ruleForm.template == 4">已选择模板四</div>
          <div class="title1" v-if="ruleForm.template == 5">已选择模板五</div>
          <div class="title2" @click="centerDialogVisible = true">编辑</div>
        </div>
      </div>

      <div class="system_wrap">
        <el-checkbox v-model="isPhone">是否使用手机号授权</el-checkbox>
      </div>

      <!--      <div class="system_wrap">-->
      <!--        <div>发布设置</div>-->
      <!--      </div>-->
      <div style="margin-top: 50px">
        <el-button
            class="save"
            type="primary"
            @click="submitForm"
        >保存
        </el-button
        >
      </div>
    </div>

    <!--    <div class="system_wrap">-->
    <!--      <div>会费到期后不允许继续使用</div>-->
    <!--      <div>-->
    <!--        <el-radio-group v-model="isTrue">-->
    <!--          <el-radio :label="0">开启</el-radio>-->
    <!--          <el-radio :label="1">关闭</el-radio>-->
    <!--        </el-radio-group>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="system_wrap">-->
    <!--      <div>到期再用几天</div>-->
    <!--      <div style="display: flex">-->
    <!--        <el-input v-model="numDate" placeholder="请输入内容"></el-input><span style="align-self: center;margin-left: 10px">天</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="system_wrap">-->
    <!--      <div>是否开启公告推送会员模板消息</div>-->
    <!--      <div>-->
    <!--        <el-radio-group v-model="isOpen">-->
    <!--          <el-radio :label="0">开启</el-radio>-->
    <!--          <el-radio :label="1">关闭</el-radio>-->
    <!--        </el-radio-group>-->
    <!--      </div>-->
    <!--    </div>-->

    <el-dialog
        title="专题区"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="35%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24_1" label-position="left"
      >
        <el-form-item prop="template" label="专题模板选择：">
          <div class="template_wrap">
            <div v-for="(item,idnex) in templateList" :key="idnex" class="template_item">
              <el-radio-group v-model="ruleForm.template">
                <el-radio :label="item.id">{{ item.title }}</el-radio>
                <img :src="item.url" alt="">
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {addSystemSetting, upSystemSetting, querySystemSettingByAssId} from "@/api/setting";

export default {
  name: "systemSet",
  data() {
    return {
      company_id: '',
      isHome: 0,
      isTrue: 0,
      numDate: '',
      isOpen: 0,
      isUse: 0,
      isPhone: 0,
      isZT: 0,
      upDate: 1,
      systemSettingId: '',
      centerDialogVisible: false,
      ruleForm: {
        template:1,
      },
      dialogVisible: false,
      rules: {
        template: [{ required: true, message: "请选择模版", trigger: "blur" }],
      },
      templateList:[
        {id:1,url:require('../../assets/images/zz_template1.png'),template:1,title:'模板一'},
        {id:2,url:require('../../assets/images/zz_template2.png'),template:2,title:'模板二'},
        {id:3,url:require('../../assets/images/zz_template3.png'),template:3,title:'模板三'},
        {id:4,url:require('../../assets/images/zz_template4.png'),template:4,title:'模板四'},
        {id:5,url:require('../../assets/images/zz_template5.png'),template:5,title:'模板五'},
      ]
    };
  },
  mounted() {
    this.queryPage();
  },
  methods: {
    async queryPage() {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const res = await querySystemSettingByAssId(data);
        console.log(res.data)
        if (res.data) {
          this.upDate = 2
          this.isHome = res.data.ifInfo == 1 ? false : true
          this.ruleForm.template = res.data.specialNum
          // this.isTrue = res.data.ifUse
          // this.numDate = res.data.days
          // this.isOpen = res.data.ifSend
          this.isUse = res.data.ifOld == 1 ? false : true
          this.isPhone = res.data.isPhoneEmpower == 1 ? false : true
          this.isZT = res.data.specialOpen == 1 ? false : true
          this.systemSettingId = res.data.systemSettingId
        }
      } catch (error) {
      }
    },
    async submitForm() {
      // if (this.numDate == '') {
      //   this.$message({
      //     message: "请选择天数",
      //     type: "error",
      //   });
      //   return
      // }else{
      let data = {
        associationId: localStorage.getItem("associationId"),
        ifInfo: this.isHome ? 0 : 1,
        // ifUse:this.isTrue,
        // days:this.numDate,
        // ifSend:this.isOpen,
        ifOld: this.isUse ? 0 : 1,
        isPhoneEmpower: this.isPhone ? 0 : 1,
        specialOpen: this.isZT ? 0 : 1,
        specialNum:this.ruleForm.template
      };
      // this.$message({
      //   message: "正在保存",
      // });
      if (this.upDate == 1) {
        await addSystemSetting(data);
      } else {
        data.systemSettingId = this.systemSettingId
        await upSystemSetting(data);
      }
      this.$message({
        message: "保存成功",
        type: "success",
      });
      await this.queryPage();
      // }
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.centerDialogVisible = false
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-radio__inner {
  border-radius: 0;
}

.system_wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .choose_title {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #51CDCB;
    margin-top: 5px;
    cursor: pointer;
  }

  .choose_title_y {
    display: flex;
    margin-top: 5px;
    .title1 {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }

    .title2 {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #51CDCB;
      margin-left: 8px;
    }

  }
}
/deep/ .template_item .el-radio__inner{
  border-radius: 50%;
}
/deep/ .template_item .el-radio-group{
  display: flex;
  flex-direction: column;
}


/deep/ .system_wrap .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333;
}

.corporateInformation-view {
  display: flex;
  padding: 16px 24px;
  background: #FFFFFF;

  .title_wrap {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    margin-top: 30px;
    margin-left: 100px;
  }

  .con_wrap {
    margin-top: 33px;
    display: flex;
    flex-direction: column;
  }
}

.save {
  width: 100px;
}
.template_wrap{
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .template_item{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    margin-bottom: 20px;
    img{
      margin-top: 5px;
      width: 80px;
      height: 80px;
    }
  }
}
.pa24_1{
  padding: 24px 24px 0 24px;
}
</style>

